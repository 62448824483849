<template>
    <custom-vue-table class="pl-3 pr-3" title="Purchase Order List" :fields="fields" :url="listUrl" :per-page="10" ref="table"
                    :extra-params="{ status, vendor }">
        <template #filters>
            <div class="btn-group ml-3">
                <drop-btn text="Filters" icon="fa fa-filter" size="md" dropdown-width="30r">
                    <div class="p-3">
                        <validated-vue-select name="Status" :options="statusOptions"
                                              label="Status"
                                              v-model="status"/>
                        <validated-ajax-vue-select name="Vendor" :url="vendorOptions"
                                                   label="Vendor"
                                                   v-model="vendor"/>
                    </div>
                </drop-btn>
            </div>
        </template>
        <template #actions="{rowData}">
            <div class="btn-group-tight">
                <btn color="primary" class="btn-basic-b" size="xs" @click="viewDetails(rowData)" text="View"/>
                <btn color="primary" v-if="rowData.status==='FA Approved'" class="btn-basic-b" size="xs"
                     @click="sendToVendor(rowData)" text="Send to Vendor"/>
                <btn color="primary" v-if="rowData.status==='Sent To Vendor'" class="btn-basic-b" size="xs"
                     @click="sendToVendor(rowData)" text="Send Details"/>
            </div>
        </template>
    </custom-vue-table>
</template>

<script>
import urls from '../../../../data/urls';

export default {
    name : 'RequestListingComponentPurchase',
    data () {
        return {
            loading       : false,
            status        : '',
            vendor        : '',
            listUrl       : urls.purchaseAdmin.purchaseOrder.list,
            vendorOptions : urls.purchaseAdmin.request.vendorOptions,
            statusOptions : [
                { value : 'PO Generated', label : 'PO Generated' },
                { value : 'PA Approved', label : 'PA Approved' },
                { value : 'FA Approved', label : 'FA Approved' },
                { value : 'FA Rejected', label : 'FA Rejected' },
                { value : 'Sent To Vendor', label : 'Sent To Vendor' }
            ],
            fields : [
                {
                    name      : 'id',
                    sortField : 'id',
                    title     : 'PO ID'
                },
                {
                    name      : 'vendor',
                    sortField : 'vendor',
                    title     : 'Vendor'
                },
                {
                    name      : 'created_date',
                    sortField : 'created_date',
                    title     : 'Date'
                },
                {
                    name      : 'status',
                    sortField : 'status',
                    title     : 'Status'
                },
                {
                    name      : 'remarks',
                    sortField : 'remarks',
                    title     : 'Remarks'
                },
                {
                    name  : '__slot:actions',
                    title : 'Actions'
                }

            ]
        };
    },
    methods : {
        viewDetails (rowData) {
            this.$router.push('/purchase/order/' + rowData.id + '/details/');
        },
        sendToVendor (rowData) {
            this.$router.push('/purchase/order-fa/' + rowData.id + '/details/');
        }
    }
};
</script>
